.offers {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 800px) and (min-width: 400px) {
    padding: 0;
    max-width: 80vw;
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 399px) and (min-width: 0) {
    padding: 0;
    max-width: 100%;
    flex-direction: column;
    align-items: center;
  }
}
.offers img {
  border-radius: 5px;
  margin: 0;
  width: 100%;
  height: 150px;
  object-fit: cover;
  @media (max-width: 800px) and (min-width: 400px) {
    padding: 0;
    border-radius: 5px;
    width: 100%;
    height: 200px;
  }
}
.offerConainer {
  margin: 10px;
  padding: 15px;
  border: solid 1px lightgrey;
  border-radius: 5px;
  //color: black;
  text-align: center;
  @media (max-width: 800px) and (min-width: 400px) {
    width: 80%;
    padding: 30px;
  }
  @media (max-width: 399px) and (min-width: 0) {
    width: 80%;
  }
  p {
    margin: 20px 0;
    align-items: center;
    text-align: center;
  }
  @media (max-width: 800px) and (min-width: 400px) {
    font-size: 16px;
  }
  @media (max-width: 399px) and (min-width: 0) {
    font-size: 12px;
  }
}
main div .offerConainer {
  border: solid 1px grey;
  border-radius: 5px;
}

main .offerConainer:hover {
  border: solid 1px grey;
  border-radius: 5px;
  color: rgb(5, 117, 61);
  box-shadow: 1px 1px 8px 3px grey;
}

.offerTitle {
  font-size: large;
  width: 100%;
  font-weight: bold;

  @media (max-width: 800px) and (min-width: 400px) {
    width: 100%;
  }
  @media (max-width: 399px) and (min-width: 0) {
    width: 80%;
    font-size: 12px;
  }
}
