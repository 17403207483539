nav {
  font-weight: 600;
  height: 100px;
  display: flex;
  justify-content: center;

  @media (max-width: 800px) and (min-width: 400px) {
    height: 100px;
  }
  @media (max-width: 399px) and (min-width: 0) {
    height: 100px;
  }
}
nav .navContainer1 {
  display: flex;
  flex-direction: column;
}
nav .navContainer2 {
  display: flex;
  flex-direction: column;
}
nav button {
  margin: 10px;
  font-size: 24px;
  display: none;
  @media (max-width: 800px) and (min-width: 400px) {
    display: flex;
    text-align: center;
    justify-content: center;
    border: none;
    background: none;
  }
  @media (max-width: 399px) and (min-width: 0) {
    display: flex;
    text-align: center;
    justify-content: center;
    border: none;
    background: none;
  }
}
nav button:focus {
  outline: none;
}
nav .hidden {
  @media (max-width: 800px) and (min-width: 400px) {
    display: none;
  }
  @media (max-width: 399px) and (min-width: 0) {
    display: none;
  }
}
nav ul {
  margin-top: 0;
  font-size: 14px;
  width: 700px;
  padding: 20px;
  list-style: none;
  display: flex;
  justify-content: space-around;
  @media (max-width: 800px) and (min-width: 400px) {
    z-index: 999999999999999;
    background: white;
    width: 500px;
    font-size: 14px;
    flex-direction: column;
  }
  @media (max-width: 399px) and (min-width: 0) {
    width: 500px;
    z-index: 999999999999999;
    background: white;
    font-size: 12px;
    flex-direction: column;
  }
}
nav ul li {
  padding: 12px;
  color: black;
  border-bottom: 2px solid white;
}
nav ul li:hover {
  border-bottom: 2px solid black;
}
a {
  text-decoration: none;
}
.navul {
  color: red;
}

.bar1,
.bar2,
.bar3 {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}
