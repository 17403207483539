.googleMapContainer {
  margin-top: 40px;
  width: 1000px;
  height: 400px;
  @media (max-width: 800px) and (min-width: 400px) {
    margin-bottom: 16px;
    padding-bottom: 24px;
  }
  @media (max-width: 399px) and (min-width: 0) {
    width: 90%;
    margin-bottom: 16px;
  }
}
.red {
  color: rgb(231, 22, 22);
}
.googleMap {
  width: 100%;
  height: 100%;
}
iframe {
  height: 400px;
}
a {
  color: black;
}
a:hover {
  color: rgb(231, 22, 74);
}
h2 {
  @media (max-width: 800px) and (min-width: 400px) {
    font-size: 24px;
  }
  @media (max-width: 399px) and (min-width: 0) {
    font-size: 20px;
  }
}
.contactCont p {
  @media (max-width: 399px) and (min-width: 0) {
    font-size: 12px;
    padding: 0 20px;
  }
}
form div .MuiFormLabel-root {
  @media (max-width: 399px) and (min-width: 0) {
    font-size: 8px;
    padding: 0 20px;
  }
}
.MuiFormLabel-root
  .MuiInputLabel-root
  .MuiInputLabel-formControl
  .MuiInputLabel-animated
  .MuiInputLabel-outlined {
  color: green;
}
