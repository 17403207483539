@import url("https://fonts.googleapis.com/css2?family=Lato&family=Montserrat:wght@400;600&family=Open+Sans&family=Playfair+Display&display=swap");

body {
  margin: 0;
}

.root {
  display: flex;
  text-align: center;
  justify-content: center;
}

.app {
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

*,
main {
  text-align: center;
  font-family: "Montserrat", sans-serif;
}
main {
  margin-bottom: 100px;
  @media (max-width: 800px) and (min-width: 400px) {
    width: 100%;
  }
}
main h2 {
  margin-top: 30px;
}
