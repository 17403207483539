main {
  margin: auto;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  @media (max-width: 800px) and (min-width: 350px) {
    padding-bottom: 100px;
    margin-bottom: 100px;
  }
}
.homeImg {
  height: 900px;
  margin: 40px 0;
  object-fit: cover;
  box-shadow: 1px 1px 8px 3px lightgrey;
  @media (max-width: 800px) and (min-width: 350px) {
    height: 700px;

    margin: 30px 20px;
  }
  @media (max-width: 349px) and (min-width: 0) {
    height: 550px;

    margin: 10px;
  }
}
div .homeContent {
  margin: 0;
  width: 600px;
  align-items: center;
  text-align: center;
  justify-content: center;
  @media (max-width: 800px) and (min-width: 350px) {
    padding: 5px;
    width: 100%;
    text-align: center;
    justify-content: center;
  }
  @media (max-width: 349px) and (min-width: 0) {
    width: 100%;
    text-align: center;
    justify-content: center;
  }
}
h2 {
  padding-top: 40px;
  @media (max-width: 800px) and (min-width: 350px) {
    font-size: 24px;
  }
  @media (max-width: 349px) and (min-width: 0) {
    font-size: 18px;
  }
}
.homeContent p {
  @media (max-width: 349px) and (min-width: 0) {
    font-size: 12px;
    padding: 0 20px;
  }
}

.quote {
  position: relative;
  padding: 6px;
  background: rgb(240, 240, 240);
  font-style: italic;
}
.quote svg {
  position: absolute;
  z-index: 999999999;
  color: black;
  top: -20px;
  left: 0;
  font-size: 48px;
  @media (max-width: 800px) and (min-width: 350px) {
    font-size: 36px;
  }
  @media (max-width: 349px) and (min-width: 0) {
    font-size: 28px;
    top: -16px;
  }
}
.author {
  font-size: 14px;
  @media (max-width: 800px) and (min-width: 350px) {
    font-size: 12px;
  }
  @media (max-width: 349px) and (min-width: 0) {
    font-size: 6px;
  }
}
.Home_reactPlayer__1a4Zu {
  width: 200px !important;
}
main div .reactPlayer {
  width: 200px !important;
  display: flex;
  justify-content: center;
  @media (max-width: 800px) and (min-width: 350px) {
    width: 100vw;
    height: 80%;
  }
  @media (max-width: 349px) and (min-width: 0) {
    width: 80vw;
    font-size: 100px;
  }
  div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.reactPlayer iframe {
  width: 100%;
  max-width: 600px;
  @media (max-width: 800px) and (min-width: 350px) {
    width: 80vw;
    height: 80%;
  }
  @media (max-width: 349px) and (min-width: 0) {
    width: 100%;
    font-size: 100px;
  }
}
