form {
  border: 2px solid lightgrey;
  border-radius: 5px;
  flex-direction: column;
  display: flex;
  padding: 60px 80px;
  @media (max-width: 800px) and (min-width: 400px) {
    padding: 60px 20px;
    font-size: 16px;
  }
  @media (max-width: 399px) and (min-width: 0) {
    margin: 10px;
    padding: 5px;
    font-size: 12px;
  }
  label .MuiFormLabel-root {
    color: green;
  }
}

form {
  padding-top: 0px;
}
.formTitle {
  font-size: 46px;
  font-weight: 600;
  font-family: "Playfair Display", serif;
  @media (max-width: 800px) and (min-width: 400px) {
    font-size: 28px;
  }
  @media (max-width: 399px) and (min-width: 0) {
    margin: 0;
    margin-top: 20px;
    font-size: 24px;
  }
}
.acceptButton {
  @media (max-width: 399px) and (min-width: 0) {
    padding: 0;
    margin: 10px;
  }
}
.formError {
  margin: 0;
  color: #db0202;
}
.checkboxContainer span {
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
  @media (max-width: 399px) and (min-width: 0) {
    width: 30px;
    height: 30px;
  }
}
.privacyPolicy {
  margin-top: 20px;
  font-size: 12px;
}
