h2 {
  @media (max-width: 800px) and (min-width: 400px) {
    font-size: 24px;
  }
  @media (max-width: 399px) and (min-width: 0) {
    font-size: 18px;
  }
}
.voucherCont {
  @media (max-width: 399px) and (min-width: 0) {
    font-size: 12px;
    padding: 0 20px;
  }
}
